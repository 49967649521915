import axios from 'axios';

export default {
  async getJobs({ commit }, data) {
    try {
      const response = await axios.get('/recruitments');
      commit('GET_JOBS', response.data);
      return response;
    } catch (e) {
      return e;
    }
  },
  async getProjectHome({ commit }, data) {
    try {
      const response = await axios.get('/recruitments?_limit=4');
      commit('GET_JOBS', response.data);
      return response;
    } catch (e) {
      return e;
    }
  },
  async getDetail({ commit }, data) {
    try {
      const response = await axios.get('/recruitments/' + data);
      commit('GET_JOB_DETAIL', response.data);
      return response;
    } catch (e) {
      return e;
    }
  },
};

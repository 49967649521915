<template>
  <div id="wrapper" class="clearfix">
    <spinner />
    <Header />
    <!-- #header end -->

    <!-- Slider
		============================================= -->
    <ArticleTwo />
    <!-- #content end -->

    <!-- Footer
		============================================= -->
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

import Spinner from '@/components/spinner';
import ArticleTwo from '@/components/ArticleTwo';

export default {
  components: { Header, Spinner, ArticleTwo },
  name: 'Landing',
};
</script>

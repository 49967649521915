<template>
  <div class="body">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>
<style scoped>
.body {
  font-family: 'Inter', sans-serif;
}
</style>

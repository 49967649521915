const state = {
  spinner: false,
};

const mutations = {
  set_spinner(state, boolean) {
    state.spinner = boolean;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

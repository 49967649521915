<template>
  <div id="wrapper" class="clearfix">
    <spinner />
    <Header />
    <!-- #header end -->

    <!-- Slider
		============================================= -->
    <Slider />
    <!-- #slider end -->

    <!-- Content
		============================================= -->
    <Content />
    <!-- #content end -->

    <!-- Footer
		============================================= -->
    <Footer />
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import Header from '@/components/Header.vue';
import Content from '@/components/Content.vue';
import Footer from '@/components/Footer.vue';
import Spinner from '@/components/spinner';

export default {
  components: { Slider, Header, Footer, Spinner, Content },
  name: 'Landing',
};
</script>

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import Home from '@/views/Home';
import ContentOne from '@/views/ContentOne';
import ContentTwo from '@/views/ContentTwo';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: {
        path: '/',
      },
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        label: 'Home',
      },
    },
    {
      path: '/content-one',
      name: 'Content One',
      component: ContentOne,
      meta: {
        label: 'Article',
      },
    },
    {
      path: '/content-two',
      name: 'Content Two',
      component: ContentTwo,
      meta: {
        label: 'Article',
      },
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
router.beforeResolve((to, from, next) => {
  if (to.path) {
    store.commit('app/set_spinner', true);
  }
  next();
});

router.afterEach(() => {
  store.commit('app/set_spinner', false);
});

export default router;
